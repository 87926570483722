import { get, put, post, delet } from "../config/api";

export const fetchProfile = (onSuccess, onError) => {
  get("/auth/profile")
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const fetchProfilePic = (onSuccess, onError) => {
  get("/user/profile/photo", {
    responseType: "arraybuffer",
  })
    .then((response) => {
      var arrayBufferView = new Uint8Array(response.data);
      var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(blob);

      onSuccess && onSuccess(imageUrl);
    })
    .catch((error) => {});
};

export const fetchMyUsers = (onSuccess, onError) => {
  get("/user")
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const fetchMyDoctors = (onSuccess, onError) => {
  get("/user/doctors")
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const fetchUserById = (userId, onSuccess, onError) => {
  get(`/user/${userId}`)
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const fetchMyNotifications = (onSuccess, onError) => {
  get("/user/notifications/all")
    .then((response) => {
      onSuccess && onSuccess(response.data);
    })
    .catch((error) => {
      onError && onError(error.message);
    });
};

export const saveUser = (userData, onSuccess, onError) => {
  if (userData._id) {
    put(`/user/${userData._id}`, userData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.response.data.message);
      });
  } else {
    post("/user", userData)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.response.data.message);
      });
  }
};

export const deleteUser = (userId, onSuccess, onError) => {
  if (userId) {
    delet(`/user/${userId}`)
      .then((response) => {
        onSuccess && onSuccess(response.data);
      })
      .catch((error) => {
        onError && onError(error.message);
      });
  }
};
