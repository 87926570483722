import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_VAIDYA_PLUS_API_URL;

// axios.defaults.headers.common['Authorization'] = 'Bearer YourAccessToken';
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    // const originalRequest = error.config;

    if (
      error.response.status === 401
      // &&
      // originalRequest.url === 'http://127.0.0.1:3000/v1/auth/token'
    ) {
      // useHistory.push('/login')
      window.location.hash = "#/login";
      return Promise.reject(error);
    }

    // if (error.response.status === 401 && !originalRequest._retry) {
    //   originalRequest._retry = true
    //   const refreshToken = localStorageService.getRefreshToken()
    //   return axios
    //     .post('/auth/token', {
    //       refresh_token: refreshToken
    //     })
    //     .then(res => {
    //       if (res.status === 201) {
    //         localStorageService.setToken(res.data)
    //         axios.defaults.headers.common['Authorization'] =
    //           'Bearer ' + localStorageService.getAccessToken()
    //         return axios(originalRequest)
    //       }
    //     })
    // }
    return Promise.reject(error);
  }
);

// Axios GET request
export function get(url, config = {}) {
  return axios.get(url, config);
}

// Axios POST request
export function post(url, data = {}, config = {}) {
  return axios.post(url, data, config);
}

// Axios PUT request
export function put(url, data = {}, config = {}) {
  return axios.put(url, data, config);
}

// Axios DELETE request
export function delet(url, config = {}) {
  return axios.delete(url, config);
}

// Axios PATCH request
export function patch(url, data = {}, config = {}) {
  return axios.patch(url, data, config);
}

// Axios HEAD request
export function head(url, config = {}) {
  return axios.head(url, config);
}

// Axios OPTIONS request
export function options(url, config = {}) {
  return axios.options(url, config);
}
